import { authStore } from "@kaplan-labs/up-auth-api-client";

const go = function () {
  const $auth = authStore.get();

  import("@braze/web-sdk").then(
    ({
      automaticallyShowInAppMessages,
      initialize,
      changeUser,
      openSession,
    }) => {
      const success = initialize(
        process.env.NEXT_PUBLIC_BRAZE_API_KEY as string,
        {
          baseUrl: process.env.NEXT_PUBLIC_BRAZE_SDK_URL as string,
          allowUserSuppliedJavascript: true,
        },
      );

      if (!success) {
        return;
      }

      if ($auth.currentUser?.id) {
        changeUser($auth.currentUser.id);
      } else {
        openSession();
      }

      automaticallyShowInAppMessages();
    },
  );
};

export const brazeInit = function () {
  const isClient =
    typeof window !== "undefined" && typeof document !== "undefined";

  authStore.listen((state) => {
    if (isClient) {
      go();
    }
  });

  if (isClient) {
    go();
  }
};
